.button {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 1.25px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    border: none;
    width: auto;
}

button:focus {
    outline: none;
}


.MuiFab-primary:hover,
a:hover,
a:focus,
a:active {
    color: #ffffff;
}

.MuiListItem-button:hover {
    color: #dc2c32;
}