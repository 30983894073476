.users {
  display: flex;
  justify-content: center;
  width: 100%;

  a:hover {
    text-decoration: none;
  }

  .mdc-list-item:hover {
    a[aria-label="supprimer"] {
      color: red;
    }

    a[aria-label="editer"] {
      color: blue;
    }
  }
}

.usersList {
  margin: 2rem;

  .mdc-fab {
    position:fixed;
    bottom:40px;
    right:40px;
  }
}