.mdc-text-field,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $grey !important;
    color: $grey !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $grey !important;
}

body .mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
    color: $grey !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
    color: $dark-grey !important;
}

.mdc-text-field--invalid .mdc-text-field__input, .mdc-text-field .mdc-text-field__input {
    caret-color: $base-color !important;
}
