.login {
    display: flex;
    flex-direction: row;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #a6a7ad;

    .left-container {
        min-height: 100vh;
        width: 50%;
        background-image: url("../../assets/images/login/left.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .right-container {
        min-height: 100vh;
        width: 50%;
    }

    #loginFormContainer {
        height: 100%;
    }

    @media (max-width: 992px) {
        display: flex;

        .right-container {
            width: 100%;
        }

        .left-container {
            display: none;
        }
    }

    #forgottenPassword {
        position: absolute;
        bottom: 20px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        color: #a6a7ad;

        a, a:hover {
            color: $base-color;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}