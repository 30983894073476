.frame {
  height: 100vh;
  display: flex;

  .mdc-drawer__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mdc-drawer-app-content {
    width: 100%;
  }
}